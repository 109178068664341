import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from 'framer-motion'
import '../styles/First.css'

export default function First(props) {


  return (
    <div className='section fp-noscroll' id='section-1-desktop'style={{height:props.WindowsHeight}} >

      <div className='homepage-container'>
        
        <motion.div className='homepage-text  p-2'>
          <h1 className='h1-text'>Rare <span style={{color: 'white'}}>and</span> variegated</h1>
          <h2 className='h2-text'>plants</h2>
          <p className='small-text p-1'>We are specialized in taking care and growing  </p>
          <p className='small-text p-1'>unique and highly variegated plants.</p>
        </motion.div>

        <div className="first-page-container-father">

          <motion.div className='first-page-container'
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1.2 }}
            transition={{ duration: 2.5 }}
          >

          </motion.div>
        </div>


        <motion.div className='shop-now-father'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2.5 }}
        >
          <a href='/Shop'>
            <div className='shop-now'>
              <p className="m-2 px-3 py-2">SHOP NOW</p>
            </div>
          </a>
        </motion.div>

        <motion.div className='scroll-down'
          initial={{ opacity: 0,y:100 }}
          animate={{ y: [100, 0], opacity: 1 }}
          transition={{ duration: 2.5 }}
        >
          <motion.p
          initial={{y:0}}
            animate={{ y: [0, 25, 0] }}
            transition={{ delay: 3.4, repeat: Infinity, duration: 4, repeatDelay: 0.2 }}
          ><FontAwesomeIcon id="arrow-down" icon="fa-solid fa-arrow-down-long" />
          </motion.p>
          <p className='scroll-down-text'><span className='span-scroll'>Scroll</span> <span>Down</span> </p>
        </motion.div>
      </div>

    </div>
  )
}
