import React from 'react'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import '../styles/Second.css'

function SectionImgUp({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  return (
    <section ref={ref} className="animeImage" >
      <span
        style={{
          transform: isInView ? 'translateY(0)' : 'translateY(100px)',
          transition: 'all 1.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s',
        }}
      >
        {children}
      </span>
    </section>
  )
}
function SectionImgUp2({ children }) {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  return (
    <section ref={ref} className="animeImage" >
      <span
        style={{
          transform: isInView ? 'translateY(0)' : 'translateY(300px)',
          transition: 'all 1.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s',
        }}
      >
        {children}
      </span>
    </section>
  )
}



export default function Second() {


  return (
    <div className='section' id="section-2-desktop">

      <div className="section1_2Container-desktop">


        <div className="section2-father">

          <SectionImgUp>
            <div className="section2-desktop">
              <div className="section2-text">
                <h4 className="perfect">PERFECT CONDITIONS</h4>
                <h2 className='plant-h2'>Plants</h2>
                <p className="plants-text p-1">Once these plants arrive in our gallery, we give them the</p>
                <p className="plants-text p-1">  perfect conditions to grow in an ideal environment</p>

              </div>

              <div className="section2-text-2">
                <a href='/Shop'>
                  <p className="shop-text-plant p-3">OUR PLANTS</p>
                </a>
              </div>

            </div>
          </SectionImgUp>

        </div>

        <div className='section2_1-father'>
          <SectionImgUp2>

            <div className="section2_1-desktop">

              <div className="section2-text">

                <h4 className="perfect">25% OF OUR PLANTS IS</h4>
                <h2 className='plant-h2'>Cuttings</h2>
                <p className="plants-text p-1">From 25% of our plants we make cuttings. We take care of them</p>
                <p className="plants-text p-1"> until they are fully rooted and ready to grow themselves</p>
                <p className="plants-text p-1"> and ready to be shipped to their new owners</p>

              </div>
              <div className="section2-text-2">
                <a href='/Shop'>
                  <p className="shop-text-plant p-3">OUR CUTTINGS</p>
                </a>
              </div>

            </div>

          </SectionImgUp2>
        </div>

      </div>

      <div className='best-sellers-text-container'>

        <div className='best-sellers-text-container-left'>
          <p className='best-sellers-text'>BEST SELLERS</p>
          <p className='best-sellers-big-text'>Be sure you can find  </p>
          <p className='best-sellers-big-text'> the perfect plant in our shop</p>
        </div>

        <div className='best-sellers-text-container-smallText'>
          <p>For us it is very important to be sure that our </p>
          <p>plants are healthy and actively growing </p>
          <p> before we sell them.</p>
        </div>

      </div>

    </div>
  )

}
